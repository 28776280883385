import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';

import { trackGAEvent } from '@/utility/analytics';
import { openMemberSupportPage } from '@/utility/freshdeskUtils';
import {
  getCurrentLanguageLabel,
  getSupportedLocaleOptions,
  setLocale,
  t
} from '@/utility/localization';
import {
  handleLogoutClickFromProductDemo,
  isSessionInSandboxEnv
} from '@/utility/productDemoUtils';
import {
  MEMBER_AFFILIATE_PRODUCTS_ROUTE,
  getRedirectToLink,
  openAppDownloadPage
} from '@/utility/routesHelper';

import { useAuthContext } from '@/contexts/AuthContext';
import { useIntercom } from '@/contexts/IntercomContext/CommonIntercomContext';

import HeadlessDropdown from '@/components/common/Form/HeadlessDropdown/HeadlessDropdown';
import NextImage from '@/components/common/NextImage';
import Icon from '@/components/npl/Icon';

import useLocale from '@/hooks/useLocale';

import { trackOpenProfileSettingBtnClick } from '@/pages/portal/utils';
import {
  SETTINGS_PAGE_MEMBERSHIP_PATH,
  SETTINGS_PAGE_PROFILE_PATH
} from '@/pages/user/constants';

function UserMenuSection({
  isCommunityAdmin,
  communityInfo,
  joinAsAffiliateProps
}) {
  const pathname = usePathname();

  const router = useRouter();
  const { currentLocale } = useLocale();
  const { user, updateUserLocale, isDemoUser, logout } = useAuthContext();
  const { shutdown } = useIntercom();

  const { showBecomeAnAffiliateNavMenuItem, onOpenJoinAsAffiliateModal } =
    joinAsAffiliateProps || {};

  const goToRoute = (route) => {
    router.push(
      getRedirectToLink({
        routeTo: route,
        redirectTo: pathname,
        activeCommunityId: communityInfo?._id
      })
    );
  };

  const handleProfileBtnClick = () => {
    trackOpenProfileSettingBtnClick(user);
    goToRoute(SETTINGS_PAGE_PROFILE_PATH);
  };

  const handleMyMembershipsClick = () => {
    trackGAEvent('clicked_my_memberships', { email: user?.email });
    goToRoute(SETTINGS_PAGE_MEMBERSHIP_PATH);
  };

  function handleLogout() {
    shutdown();

    if (isSessionInSandboxEnv() && isDemoUser) {
      handleLogoutClickFromProductDemo();
      return;
    }

    logout(true, true, '/');
  }

  const profileImage = user?.learner?.profileImage;

  const fullName = `${user?.learner?.firstName ?? ''} ${
    user?.learner?.lastName ?? ''
  }`;

  const renderOptionItem = ({
    dataTestId = '',
    onClick,
    icon,
    label,
    tailIcon
  }) => {
    return (
      <div className="px-8">
        <div
          data-test-id={dataTestId}
          className="flex items-center space-x-12 rounded-8 px-8 py-16 transition-all hover:bg-npl-neutral-light-alpha-3"
          role="button"
          tabIndex={0}
          onClick={onClick}>
          <Icon name={icon} width={18} height={18} fillOpacity={0.65} />
          <p className="text-label-md text-dark-1b">{label}</p>
          {tailIcon && (
            <Icon
              name={tailIcon}
              width={18}
              height={18}
              fillOpacity={0.65}
            />
          )}
        </div>
      </div>
    );
  };

  const renderOptionDivider = () => (
    <div className="my-8 border-b-1 border-npl-separator-alpha" />
  );

  return (
    <div className="c-UserMenuSection">
      {user && (
        <HeadlessDropdown
          placement="bottom-start"
          key="user-menu"
          renderField={() => {
            return (
              <div
                className={`relative flex cursor-pointer items-center space-x-4 rounded-[48px] px-4 py-2 mix-blend-multiply transition-all hover:bg-npl-neutral-light-alpha-3`}
                data-testid="cmp-nav-user-profile-icon">
                <div className="flex aspect-square w-32 min-w-[32px] items-center">
                  <NextImage
                    mobileImgProps={{
                      src: profileImage,
                      width: 32,
                      height: 32,
                      objectFit: 'cover'
                    }}
                    className="!h-32 w-32 rounded-full"
                  />
                </div>
                <Icon
                  name="menu-01"
                  width={16}
                  height={16}
                  fill="#1B1B1859"
                />
              </div>
            );
          }}
          renderOptions={({ closeDropdown }) => {
            if (isDemoUser) {
              return null;
            }

            return (
              <div className="flex flex-col rounded-12 bg-npl-base-white py-8 xl:min-w-[240px]">
                {/* Profile */}
                <div className="px-8">
                  <div
                    className="flex flex-row items-center rounded-12 p-8 transition-all hover:bg-npl-neutral-light-alpha-3"
                    role="button"
                    tabIndex={0}
                    onClick={(e) => {
                      if (!isDemoUser) handleProfileBtnClick(e);
                    }}>
                    <div
                      className={`relative hidden w-[44px] min-w-[44px] cursor-pointer items-center rounded-28 p-4 transition-all hover:bg-npl-neutral-light-alpha-3 md:flex`}>
                      <NextImage
                        mobileImgProps={{
                          src: profileImage,
                          width: 32,
                          height: 32,
                          objectFit: 'cover'
                        }}
                        className="!h-32 rounded-full"
                      />
                    </div>
                    <div className="ml-12 flex flex-col items-stretch">
                      <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary line-clamp-2	max-w-300">
                        {fullName}
                      </span>
                      <span className="break-all text-label-md text-npl-text-icon-on-light-surface-secondary">
                        {user?.email}
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div className="my-8 border-b-1 border-npl-separator-alpha" /> */}
                {renderOptionDivider()}
                {/* Profile Settings Option */}
                {renderOptionItem({
                  dataTestId: 'profile-settings-button',
                  onClick: handleProfileBtnClick,
                  icon: 'user-01',
                  label: t('profile-settings')
                })}
                {/* My Memberships option */}
                {renderOptionItem({
                  dataTestId: 'manage-membership-button',
                  onClick: handleMyMembershipsClick,
                  icon: 'settings-04',
                  label: t('my-memberships')
                })}
                {renderOptionDivider()}

                {/* Affiliate */}
                {user?.existsAffiliate ||
                showBecomeAnAffiliateNavMenuItem ? (
                  <>
                    {showBecomeAnAffiliateNavMenuItem &&
                      renderOptionItem({
                        dataTestId: 'become-affiliate-button',
                        onClick: () => {
                          trackGAEvent('nav-dropdown_become-affiliate');
                          onOpenJoinAsAffiliateModal();
                          closeDropdown();
                        },
                        icon: 'user-right-01',
                        label: t('become-an-affiliate')
                      })}

                    {user?.existsAffiliate &&
                      renderOptionItem({
                        dataTestId: 'go-to-affilaite-products-page-btn',
                        onClick: () => {
                          trackGAEvent(
                            'nav-dropdown_go-to-affiliate-products-page'
                          );
                          goToRoute(MEMBER_AFFILIATE_PRODUCTS_ROUTE);
                        },
                        icon: 'user-money',
                        label: t('affiliate')
                      })}

                    {renderOptionDivider()}
                  </>
                ) : null}

                <HeadlessDropdown
                  placement="left"
                  renderOptions={({ closeDropdown }) => {
                    return (
                      <ul className="flex min-w-[180px] flex-col rounded-12 bg-npl-base-white p-8 shadow-npl-styles-shadow-02">
                        {getSupportedLocaleOptions().map((option) => (
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                          <li
                            key={option.value}
                            className="flex cursor-pointer flex-row items-center rounded-12 px-8 py-16 transition-all hover:bg-npl-neutral-light-alpha-3"
                            value={option.value}
                            onClick={async () => {
                              closeDropdown();
                              await updateUserLocale(option.value);
                              setLocale(option.value);
                            }}>
                            <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                              {option.label}
                            </span>
                            {currentLocale === option.value && (
                              <Icon
                                name="check"
                                width={16}
                                height={16}
                                className="ml-auto"></Icon>
                            )}
                          </li>
                        ))}
                      </ul>
                    );
                  }}
                  renderField={({ isOpen }) => (
                    <div className="px-8">
                      <div
                        data-testid="language-selector-button"
                        className={classNames(
                          'flex items-center space-x-12 rounded-8 px-8 py-16 transition-all hover:bg-npl-neutral-light-alpha-3',
                          {
                            'bg-npl-neutral-light-alpha-3': isOpen
                          }
                        )}
                        role="button"
                        tabIndex={0}>
                        <Icon
                          name="globe-02"
                          width={18}
                          height={18}
                          fillOpacity={0.65}
                        />
                        <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                          {`${t('language')}: ${getCurrentLanguageLabel(
                            currentLocale
                          )}`}
                        </span>
                        <Icon
                          name="chevron-right"
                          width={16}
                          height={16}
                          fillOpacity={0.65}
                        />
                      </div>
                    </div>
                  )}
                />
                {!isCommunityAdmin && (
                  <>
                    {renderOptionItem({
                      dataTestId: 'download-app-button',
                      onClick: openAppDownloadPage,
                      icon: 'hone-01',
                      label: t('download-the-nas-io-app')
                    })}
                    {renderOptionItem({
                      dataTestId: 'contact-support-button',
                      onClick: () => {
                        trackGAEvent('nav-dropdown_contact-support');
                        openMemberSupportPage();
                      },
                      icon: 'message-question-square',
                      label: t('contact-support')
                    })}
                  </>
                )}
                {renderOptionItem({
                  dataTestId: 'logout-button',
                  onClick: () => {
                    closeDropdown();
                    handleLogout();
                  },
                  icon: 'log-out',
                  label: t('logout')
                })}
              </div>
            );
          }}
        />
      )}
    </div>
  );
}

export default UserMenuSection;
